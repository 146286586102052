import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../select/Select";
import classes from "./Dropdown.module.css";

export default function Dropdown({
  label,
  options,
  value,
  onChange,
  placeholder,
  icon: Icon,
  disabled,
}) {
  const handleChange = (newValue) => {
    if (typeof onChange === "function") {
      onChange(newValue);
    }
  };

  return (
    <div className={classes.dropdown}>
      <label className={classes.label}>{label}</label>
      <Select onValueChange={handleChange} value={value} disabled={disabled}>
        <SelectTrigger className={classes.trigger}>
          <SelectValue placeholder={placeholder}>
            {options.find((option) => option.value === value)?.label ||
              placeholder}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => (
            <SelectItem
              key={option.value}
              value={option.value}
              onChange={handleChange}
            >
              <div
                className={`${classes.option} ${
                  value === option.value ? classes.selected : ""
                }`}
              >
                {Icon && <Icon className={classes.icon} />}
                <span>{option.label}</span>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
