// components/UI/carousel/Carousel.jsx
import React, { useState, useCallback } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import classes from "./Carousel.module.css";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const goToSlide = useCallback((index) => {
    setIsTransitioning(true);
    setCurrentIndex(index);
  }, []);

  const goToPrevious = useCallback(() => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    goToSlide(newIndex);
  }, [currentIndex, images.length, goToSlide]);

  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    goToSlide(newIndex);
  }, [currentIndex, images.length, goToSlide]);

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  return (
    <div className={classes.carousel}>
      <div className={classes.slideContainer}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`${classes.slide} ${
              index === currentIndex ? classes.activeSlide : ""
            }`}
            style={{
              transform: `translateX(${100 * (index - currentIndex)}%)`,
            }}
            onTransitionEnd={handleTransitionEnd}
          />
        ))}
      </div>
      <button
        onClick={goToPrevious}
        className={`${classes.navButton} ${classes.prevButton}`}
        aria-label="Previous image"
        disabled={isTransitioning}
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={goToNext}
        className={`${classes.navButton} ${classes.nextButton}`}
        aria-label="Next image"
        disabled={isTransitioning}
      >
        <ChevronRight size={24} />
      </button>
      <div className={classes.dotsContainer}>
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`${classes.dot} ${
              index === currentIndex ? classes.activeDot : ""
            }`}
            aria-label={`Go to slide ${index + 1}`}
            disabled={isTransitioning}
          />
        ))}
      </div>
      <div className={classes.thumbnails}>
        {images.map((image, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`${classes.thumbnail} ${
              index === currentIndex ? classes.activeThumbnail : ""
            }`}
            disabled={isTransitioning}
          >
            <img
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={classes.thumbnailImage}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
