import React from "react";
import { SignUp } from "@clerk/clerk-react";
import classes from "./signup.module.css";
import { Link } from "react-router-dom";

export default function Signup() {
  return (
    <div className={classes.signupContainer}>
      <div className={classes.signupCard}>
        <SignUp
          signInUrl="/signin"
          appearance={{
            elements: {
              rootBox: classes.clerkRoot,
              card: classes.clerkCard,
              headerTitle: classes.headerTitle,
              headerSubtitle: classes.headerSubtitle,
              socialButtonsBlockButton: classes.socialButton,
              socialButtonsBlockButtonArrow: classes.socialButtonArrow,
              socialButtonsBlockButtonText: classes.socialButtonText,
              dividerLine: classes.dividerLine,
              dividerText: classes.dividerText,
              formButtonPrimary: classes.submitButton,
              formFieldInput: classes.input,
              formFieldLabel: classes.label,
              formFieldAction: classes.formFieldAction,
              footerActionLink: classes.footerActionLink,
            },
          }}
        />
      </div>
      <Link to="/" className={classes.redirectLink}>
        Looking for offers? Browse without signing up
      </Link>
    </div>
  );
}
