import React, { useState, useRef, useEffect } from "react";
import classes from "./Slider.module.css";

export const Slider = ({
  min = 0,
  max = 100,
  step = 1,
  value = [0],
  onValueChange,
}) => {
  const [sliderValue, setSliderValue] = useState(value[0]);
  const sliderRef = useRef(null);

  useEffect(() => {
    setSliderValue(value[0]);
  }, [value]);

  const handleSliderChange = (event) => {
    const newValue = Math.min(Math.max(parseInt(event.target.value), min), max);
    setSliderValue(newValue);
    onValueChange([newValue]);
  };

  const getBackgroundSize = () => {
    return {
      backgroundSize: `${((sliderValue - min) * 100) / (max - min)}% 100%`,
    };
  };

  return (
    <div className={classes.sliderContainer}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={handleSliderChange}
        className={classes.slider}
        ref={sliderRef}
        style={getBackgroundSize()}
      />
    </div>
  );
};
