// components/Footer.jsx
import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import classes from "./Footer.module.css";

export default function Footer() {
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.companyInfo}>
            <h2 className={classes.logo}>Excursify</h2>
            <p className={classes.description}>
              Discover the world with Excursify. We offer the best flight deals
              and exceptional service to make your journey unforgettable.
            </p>
          </div>
          <div className={classes.linksSection}>
            <div className={classes.linkColumn}>
              <h3 className={classes.columnTitle}>Company</h3>
              <ul className={classes.linkList}>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/careers">Careers</a>
                </li>
                <li>
                  <a href="/press">Press</a>
                </li>
              </ul>
            </div>
            <div className={classes.linkColumn}>
              <h3 className={classes.columnTitle}>Support</h3>
              <ul className={classes.linkList}>
                <li>
                  <a href="/help">Help Center</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li>
                  <a href="/faq">FAQ</a>
                </li>
              </ul>
            </div>
            <div className={classes.linkColumn}>
              <h3 className={classes.columnTitle}>Legal</h3>
              <ul className={classes.linkList}>
                <li>
                  <a href="/terms">Terms of Service</a>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/cookies">Cookie Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.bottom}>
          <div className={classes.socialLinks}>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </a>
          </div>
          <div className={classes.copyright}>
            © {new Date().getFullYear()} Excursify. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
