import React, { useState, useRef, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import classes from "./Select.module.css";

const Select = ({ children, onValueChange, value, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (newValue) => {
    if (typeof onValueChange === "function") {
      onValueChange(newValue);
    }
    setIsOpen(false); // Close dropdown after value change
  };

  return (
    <div className={classes.select} ref={selectRef}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          isOpen,
          setIsOpen,
          onChange: handleChange,
          value,
          disabled,
        })
      )}
    </div>
  );
};

const SelectTrigger = ({ children, isOpen, setIsOpen, disabled }) => {
  return (
    <button
      className={`${classes.selectTrigger} ${isOpen ? classes.open : ""}`}
      onClick={() => !disabled && setIsOpen(!isOpen)}
      disabled={disabled}
    >
      {children}
      <ChevronDown className={classes.icon} />
    </button>
  );
};

const SelectValue = ({ children, placeholder }) => {
  return <span className={classes.selectValue}>{children || placeholder}</span>;
};

const SelectContent = ({ children, isOpen }) => {
  if (!isOpen) return null;
  return <div className={classes.selectContent}>{children}</div>;
};

const SelectItem = ({ children, value, onChange }) => {
  const handleClick = () => {
    onChange(value);
  };

  return (
    <div className={classes.selectItem} onClick={handleClick}>
      {children}
    </div>
  );
};

export { Select, SelectTrigger, SelectValue, SelectContent, SelectItem };
