// HomePage.js
import React, { useState } from "react";
import { PlaneTakeoff, LucideCalendarSearch } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/UI/dropdown/Dropdown";
import PriceSlider from "../../components/UI/price-slider/PriceSlider";
import Button from "../../components/UI/button/Button";
import classes from "./home.module.css";

const europeanCities = [
  { value: "LHR", label: "London" },
  { value: "CDG", label: "Paris" },
  { value: "BCN", label: "Barcelona" },
  { value: "FCO", label: "Rome" },
  { value: "AMS", label: "Amsterdam" },
  { value: "TXL", label: "Berlin" },
  { value: "MAD", label: "Madrid" },
  { value: "VIE", label: "Vienna" },
  { value: "PRG", label: "Prague" },
  { value: "ATH", label: "Athens" },
];

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export default function HomePage() {
  const [price, setPrice] = useState(1000);
  const [departure, setDeparture] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [isDepartureEnabled, setIsDepartureEnabled] = useState(false);

  const navigate = useNavigate();

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
    setIsDepartureEnabled(true);
  };

  const handleSearch = () => {
    const searchParams = new URLSearchParams({
      departure,
      month: selectedMonth,
      price: price.toString(),
    });

    navigate(`/booking-offers?${searchParams.toString()}`);
  };

  return (
    <div className={classes.page}>
      <main className={classes.main}>
        <div className={classes.container}>
          <h1 className={classes.title}>Discover Your Next Adventure</h1>
          <div className={classes.form}>
            <div className={classes.formGrid}>
              <Dropdown
                label="Travel Month"
                options={months}
                value={selectedMonth}
                onChange={handleMonthChange}
                placeholder="Select travel month"
                icon={LucideCalendarSearch}
              />
              <Dropdown
                label="Departure City"
                options={europeanCities}
                value={departure}
                onChange={setDeparture}
                placeholder="Select departure city"
                icon={PlaneTakeoff}
                disabled={!isDepartureEnabled}
              />
            </div>
            <PriceSlider value={price} onChange={setPrice} />
            <Button className={classes.searchButton} onClick={handleSearch}>
              Search
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}
