// components/Header.jsx
import React from "react";
import { Home, User, Heart, LogOut } from "lucide-react";
import classes from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";

export default function Header() {
  const { isSignedIn, signOut } = useAuth();
  return (
    <header className={classes.header}>
      <div className={classes.container}>
        <NavLink to="/" className={classes.logo}>
          Excursify
        </NavLink>
        <nav className={classes.nav}>
          <ul className={classes.navList}>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? `${classes.navLink} ${classes.active}`
                    : classes.navLink
                }
              >
                <Home className={classes.icon} />
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/favorites"
                className={({ isActive }) =>
                  isActive
                    ? `${classes.navLink} ${classes.active}`
                    : classes.navLink
                }
              >
                <Heart className={classes.icon} />
                <span>Favorites</span>
              </NavLink>
            </li>
            {isSignedIn ? (
              <li>
                <NavLink to="#" className={classes.navLink} onClick={signOut}>
                  <LogOut className={classes.icon} />
                  <span>Logout</span>
                </NavLink>
              </li>
            ) : (
              <li>
                <NavLink
                  to="/signin"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navLink} ${classes.active}`
                      : classes.navLink
                  }
                >
                  <User className={classes.icon} />
                  <span>Signin</span>
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}
