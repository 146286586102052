import React from "react";
import classes from "./Section.module.css";

export default function Section({ children }) {
  return (
    <div className={classes.container}>
      <section className={classes.section}>{children}</section>
    </div>
  );
}
