import React from "react";
import { Slider } from "../slider/Slider";
import classes from "./PriceSlider.module.css";

export default function PriceSlider({
  value,
  onChange,
  min = 0,
  max = 1000,
  step = 10,
}) {
  return (
    <div className={classes.priceSlider}>
      <label htmlFor="price-range" className={classes.label}>
        Price Range: €{value}
      </label>
      <Slider
        id="price-range"
        min={min}
        max={max}
        step={step}
        value={[value]}
        onValueChange={(values) => onChange(values[0])}
        className={classes.slider}
      />
    </div>
  );
}
