import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import TravelCard from "../../components/UI/travel-card/TravelCard";
import classes from "./booking-offers.module.css";

export default function BookingOffers() {
  const [travelPackage, setTravelPackage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchOffers = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const searchParams = new URLSearchParams(location.search);
        const from = searchParams.get("departure");
        const month = searchParams.get("month");
        const price = parseFloat(searchParams.get("price"));

        const response = await axios.get(
          "http://localhost:3020/offer-packages",
          {
            params: {
              departure: from,
              month,
              price,
            },
          }
        );

        console.log("API Response: ", response.data);

        if (
          response.data &&
          response.data.success &&
          Array.isArray(response.data.data) &&
          response.data.data.length > 0
        ) {
          const travelOffer = response.data.data[0]; // Take the first package
          console.log("Selected travel package:", travelOffer);
          console.log("Flight offers:", travelOffer.flightOffers);
          console.log("Hotel offers:", travelOffer.hotelOffers);
          setTravelPackage(travelOffer);
        } else {
          setError("No offers found or invalid response format");
        }
      } catch (error) {
        console.error("Error fetching offers:", error);
        setError("Failed to fetch offers. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, [location.search]);

  const renderContent = () => {
    if (isLoading) {
      return <p className={classes.loading}>Loading offers...</p>;
    }

    if (error) {
      return <p className={classes.error}>{error}</p>;
    }

    if (!travelPackage) {
      return (
        <p className={classes.noResults}>
          No results found. Please try again with different search criteria.
        </p>
      );
    }

    return (
      <TravelCard
        flightOffers={travelPackage.flightOffers || []}
        hotelOffers={travelPackage.hotelOffers || []}
        fromCode={travelPackage.departure || ""}
        toCode={travelPackage.destination || ""}
        onRefreshOffer={() => {
          // Implement refresh logic here
          console.log("Refresh offer clicked");
        }}
      />
    );
  };

  return (
    <div className={classes.page}>
      <main className={classes.main}>
        <div className={classes.container}>
          <h1 className={classes.title}>Your Travel Option</h1>
          <div className={classes.resultsColumn}>{renderContent()}</div>
        </div>
      </main>
    </div>
  );
}
