import React, { useState, useEffect } from "react";
import {
  Plane,
  Briefcase,
  Calendar,
  Heart,
  EuroIcon,
  Hotel,
  Star,
  Clock,
  ArrowRight,
} from "lucide-react";
import Carousel from "../carousel/Carousel";
import classes from "./TravelCard.module.css";
import Button from "../button/Button";

const TravelCard = ({
  flightOffers = [],
  hotelOffers = [],
  fromCode = "",
  toCode = "",
  onRefreshOffer,
}) => {
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(0);
  const [selectedHotelIndex, setSelectedHotelIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if (flightOffers[selectedFlightIndex] && hotelOffers[selectedHotelIndex]) {
      const flightCost =
        parseFloat(flightOffers[selectedFlightIndex].price?.total) || 0;
      const hotelCost =
        parseFloat(hotelOffers[selectedHotelIndex].offers?.[0]?.price?.total) ||
        0;
      setTotalCost(flightCost + hotelCost);
    }
  }, [selectedFlightIndex, selectedHotelIndex, flightOffers, hotelOffers]);

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const formatTime = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatDuration = (durationString) => {
    if (!durationString) return "";
    const hours = durationString.match(/(\d+)H/);
    const minutes = durationString.match(/(\d+)M/);
    return `${hours ? hours[1] : "0"}h ${minutes ? minutes[1] : "0"}m`;
  };

  const calculateDaysBetween = (departureDate, returnDate) => {
    if (!departureDate || !returnDate) return 0;
    const departure = new Date(departureDate);
    const returnD = new Date(returnDate);
    const diffTime = Math.abs(returnD - departure);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const hotelImages = hotelOffers[selectedHotelIndex]?.hotel?.media
    ? hotelOffers[selectedHotelIndex].hotel.media
        .map((media) => media.uri)
        .slice(0, 3)
    : [
        "https://cf.bstatic.com/static/img/theme-index/bg_luxury/869918c9da63b2c5685fce05965700da5b0e6617.jpg",
        "https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D",
        "https://media.istockphoto.com/id/119926339/photo/resort-swimming-pool.jpg?s=612x612&w=0&k=20&c=9QtwJC2boq3GFHaeDsKytF4-CavYKQuy1jBD2IRfYKc=",
      ];

  if (flightOffers.length === 0 || hotelOffers.length === 0) {
    return (
      <div className={classes.card}>
        <div className={classes.cardBody}>
          <p>No offers available at the moment. Please try again later.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <div className={classes.cardHeader}>
        <div className={classes.tripInfo}>
          <Button
            className={classes.checkOffersButton}
            onClick={onRefreshOffer}
            aria-label="Check other offers"
          >
            Check other offers
          </Button>

          <h2 className={classes.tripSummary}>
            {fromCode} to {toCode} (Round Trip)
          </h2>
          <div className={classes.tripActions}>
            <button
              className={`${classes.favoriteButton} ${
                isFavorite ? classes.favoriteActive : ""
              }`}
              onClick={toggleFavorite}
              aria-label={
                isFavorite ? "Remove from favorites" : "Add to favorites"
              }
            >
              <Heart className={classes.favoriteIcon} />
            </button>
            <div className={classes.tripCost}>
              <EuroIcon className={classes.costIcon} />
              <span className={classes.costValue}>{totalCost.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.cardBody}>
        <div className={classes.flightInfo}>
          <div className={classes.flightInfoHeader}>
            <h3 className={classes.sectionTitle}>
              <Plane className={classes.titleIcon} />
              Flight Options
            </h3>
          </div>
          <div className={classes.flightOptions}>
            {flightOffers.map((offer, index) => (
              <div
                key={offer.id || index}
                className={`${classes.flightOption} ${
                  index === selectedFlightIndex ? classes.selectedFlight : ""
                }`}
                onClick={() => setSelectedFlightIndex(index)}
              >
                <div className={classes.flightMainInfo}>
                  <div className={classes.flightRoute}>
                    <div className={classes.flightLegs}>
                      {offer.itineraries &&
                        offer.itineraries.map((itinerary, itineraryIndex) => (
                          <div
                            key={itineraryIndex}
                            className={classes.flightLeg}
                          >
                            <div className={classes.legInfoTimeline}>
                              <div className={classes.flightDetails}>
                                <div className={classes.flightIcons}>
                                  <Plane
                                    className={`${classes.planeIcon} ${
                                      itineraryIndex === 1
                                        ? classes.returnIcon
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div className={classes.flightInfo}>
                                  <div className={classes.flightMainDetails}>
                                    <span className={classes.flightTime}>
                                      {formatTime(
                                        itinerary.segments[0]?.departure?.at
                                      )}
                                    </span>
                                    <span className={classes.flightIataCode}>
                                      {
                                        itinerary.segments[0]?.departure
                                          ?.iataCode
                                      }
                                    </span>
                                    <div className={classes.flightRouteVisual}>
                                      <div
                                        className={classes.flightRouteLine}
                                      ></div>
                                      <ArrowRight
                                        className={classes.arrowIcon}
                                      />
                                    </div>
                                    <span className={classes.flightTime}>
                                      {formatTime(
                                        itinerary.segments[
                                          itinerary.segments.length - 1
                                        ]?.arrival?.at
                                      )}
                                    </span>
                                    <span className={classes.flightIataCode}>
                                      {
                                        itinerary.segments[
                                          itinerary.segments.length - 1
                                        ]?.arrival?.iataCode
                                      }
                                    </span>
                                  </div>
                                  <div
                                    className={classes.flightSecondaryDetails}
                                  >
                                    <span className={classes.flightDate}>
                                      {formatDate(
                                        itinerary.segments[0]?.departure?.at
                                      )}
                                    </span>
                                    <span className={classes.flightDuration}>
                                      {formatDuration(itinerary.duration)}
                                    </span>
                                    <span className={classes.flightType}>
                                      {itinerary.segments.length === 1
                                        ? "Direct"
                                        : `${
                                            itinerary.segments.length - 1
                                          } stop${
                                            itinerary.segments.length > 2
                                              ? "s"
                                              : ""
                                          }`}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={classes.flightPrice}>
                    €{offer.price?.total || "N/A"}
                  </div>
                </div>
                <div className={classes.flightAdditionalInfo}>
                  <div className={classes.flightClass}>
                    <span className={classes.flightLabel}>Class:</span>
                    <span>
                      {offer.travelerPricings?.[0]?.fareDetailsBySegment?.[0]
                        ?.cabin || "N/A"}
                    </span>
                  </div>
                  <div className={classes.flightSeat}>
                    <span className={classes.flightLabel}>Seat:</span>
                    <span>
                      {offer.travelerPricings?.[0]?.fareDetailsBySegment?.[0]
                        ?.class || "N/A"}
                    </span>
                  </div>
                  <div className={classes.flightBaggage}>
                    <Briefcase className={classes.baggageIcon} />
                    <span>
                      {offer.travelerPricings?.[0]?.fareDetailsBySegment?.[0]
                        ?.includedCheckedBags?.quantity || "0"}
                    </span>
                  </div>
                  <div className={classes.flightDuration}>
                    <Clock className={classes.durationIcon} />
                    <span>
                      {calculateDaysBetween(
                        offer.itineraries?.[0]?.segments?.[0]?.departure?.at,
                        offer.itineraries?.[1]?.segments?.[0]?.departure?.at
                      )}{" "}
                      days
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.hotelInfo}>
          <h3 className={classes.sectionTitle}>
            <Hotel className={classes.titleIcon} />
            Hotel Options
          </h3>
          <div className={classes.hotelOptions}>
            {hotelOffers.map((offer, index) => (
              <button
                key={offer.id || index}
                className={`${classes.hotelOption} ${
                  index === selectedHotelIndex ? classes.selectedHotel : ""
                }`}
                onClick={() => setSelectedHotelIndex(index)}
              >
                {offer.hotel?.name || `Hotel Option ${index + 1}`}
              </button>
            ))}
          </div>
          {hotelOffers[selectedHotelIndex] && (
            <div className={classes.selectedHotelInfo}>
              <h4 className={classes.hotelName}>
                {hotelOffers[selectedHotelIndex].hotel?.name ||
                  "Hotel Name N/A"}
              </h4>
              <div className={classes.hotelRating}>
                {[
                  ...Array(hotelOffers[selectedHotelIndex].hotel?.rating || 0),
                ].map((_, i) => (
                  <Star
                    key={i}
                    className={classes.starIcon}
                    size={16}
                    fill="currentColor"
                  />
                ))}
              </div>
              <div className={classes.hotelPrice}>
                <div>
                  €
                  {(
                    (parseFloat(
                      hotelOffers[selectedHotelIndex].offers?.[0]?.price?.total
                    ) || 0) /
                    calculateDaysBetween(
                      hotelOffers[selectedHotelIndex].offers?.[0]?.checkInDate,
                      hotelOffers[selectedHotelIndex].offers?.[0]?.checkOutDate
                    )
                  ).toFixed(2)}{" "}
                  per night
                </div>
                <div>
                  €
                  {(
                    parseFloat(
                      hotelOffers[selectedHotelIndex].offers?.[0]?.price?.total
                    ) || 0
                  ).toFixed(2)}{" "}
                  total
                </div>
              </div>
              <div className={classes.hotelDates}>
                <div className={classes.checkInDate}>
                  <Clock className={classes.dateIcon} />
                  <div className={classes.dateInfo}>
                    <span className={classes.dateLabel}>Check-in time</span>
                    <span className={classes.date}>
                      {formatDate(
                        hotelOffers[selectedHotelIndex].offers?.[0]?.checkInDate
                      )}
                    </span>
                  </div>
                </div>
                <div className={classes.totalDays}>
                  {calculateDaysBetween(
                    hotelOffers[selectedHotelIndex].offers?.[0]?.checkInDate,
                    hotelOffers[selectedHotelIndex].offers?.[0]?.checkOutDate
                  )}{" "}
                  nights
                </div>
                <div className={classes.checkOutDate}>
                  <Clock className={classes.dateIcon} />
                  <div className={classes.dateInfo}>
                    <span className={classes.dateLabel}>Check-out time</span>
                    <span className={classes.date}>
                      {formatDate(
                        hotelOffers[selectedHotelIndex].offers?.[0]
                          ?.checkOutDate
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <Carousel images={hotelImages} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.cardFooter}>
        <Button className={classes.bookButton}>
          <Calendar className={classes.buttonIcon} />
          Book Now
        </Button>
      </div>
    </div>
  );
};

export default TravelCard;
