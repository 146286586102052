import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./layout/Root-layout/root-layout";
import Home from "./pages/home/home";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import Signin from "./pages/signin/signin";
import Signup from "./pages/signup/signup";
import Favorites from "./pages/favorites/favorites";
import BookingOffers from "./pages/booking-offers/booking-offers";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/booking-offers",
          element: <BookingOffers />,
        },
        {
          path: "/favorites",
          element: (
            <ProtectedRoute>
              <Favorites />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/signin",
      element: <Signin />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
  ]);

  return <RouterProvider router={router} />;
}
