import React from "react";
import Section from "../../components/section/Section";
import { SignIn } from "@clerk/clerk-react";

export default function Signin() {
  return (
    <Section>
      <SignIn signUpUrl="signup" />
    </Section>
  );
}
